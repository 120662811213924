<template>
  <div class="h-100">
    <div
      v-if="displayWarning"
      class="row justify-content-center align-items-center h-100 w-100"
    >
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
        <div class="alert alert-warning text-center">
          Please add one or more players before starting the round
        </div>
        <div class="m text-center" v-if="!showPlayerForm">
          <b-button class="btn btn-success w-100" @click="addplayer"
            >Add Player</b-button
          >
        </div>
      </div>
    </div>
    <div
      v-else
      class="row no-gutters w-100 justify-content-center align-items-center"
    >
      <div
        v-if="showPlayerForm"
        class="h-50 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8"
      >
        <b-form class="playerForm">
          <h4 class="text-center mb-3">
            <span v-if="!editingPlayer">Add Player to Group</span>
            <span v-else>Edit Player</span>
          </h4>
          <b-form-group label="Enter Player Name" label-for="player-name">
            <b-input v-model="player.name" id="player-name" type="text">
            </b-input>
          </b-form-group>
          <b-form-group label="Enter Handicap" label-for="player-handicap">
            <b-input
              v-model="player.handicap"
              id="player-handicap"
              type="number"
              min="0"
              max="100"
            >
            </b-input>

            <div class="m-auto slider">
              <b-form-input
                v-model="player.handicap"
                type="range"
                min="0"
                max="100"
              ></b-form-input>
            </div>
          </b-form-group>

          <div class="row">
            <div
              class="col-12 col-sm-12 mt-2"
              :class="{
                'col-md-6 col-lg-6': editingPlayer,
                'col-md-4 col-lg-4': !editingPlayer,
              }"
            >
              <b-button class="w-100 btn btn-danger" @click="cancelAddPlayer">
                Cancel
              </b-button>
            </div>
            <div
              class="col-12 col-sm-12 mt-2"
              :class="{
                'col-md-6 col-lg-6': editingPlayer,
                'col-md-4 col-lg-4': !editingPlayer,
              }"
            >
              <b-button
                class="w-100 btn btn-succes"
                @click="save(true)"
                :disabled="!canSave"
                variant="primary"
                >Save</b-button
              >
            </div>
            <div
              class="col-12 col-sm-12 col-md-4 col-lg-4 mt-2"
              v-if="!editingPlayer"
            >
              <b-button
                class="w-100 btn btn-success"
                @click="save(false)"
                :disabled="!canSave"
                >Save & Add New</b-button
              >
            </div>
          </div>
        </b-form>
      </div>
      <div
        v-if="hasPlayers"
        class="row w-100 justify-content-center"
        :class="{ 'h-50': showPlayerForm, 'h-100': !showPlayerForm }"
      >
        <div class="h-100 pt-3 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8">
          <h4 class="text-center">Current Group</h4>
          <div class="mt-3 mb-3 text-center" v-if="!showPlayerForm">
            <b-button class="btn btn-success w-100" @click="addplayer"
              >Add Player</b-button
            >
          </div>
          <div>
            <b-table
              striped
              class="table"
              :items="matchPlayers"
              :fields="playerFields"
            >
              <template #cell(actions)="data">
                <div>
                  <div class="text-center">
                    <b-button
                      variant="success"
                      class="m-1"
                      @click="editPlayer(data.item.id)"
                    >
                      <b-icon-pencil />
                    </b-button>
                    <b-button
                      variant="danger"
                      class="m-1"
                      @click="removePlayer(data.item.id)"
                      ><b-icon-trash></b-icon-trash>
                    </b-button>
                  </div>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vuex from "vuex";

export default {
  name: "MatchPlayers",
  methods: {
    async removePlayer(playerId) {
      const player = this.players.filter((p) => p.id === playerId)[0];
      const confirmed = await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to remove ${player.name} from this grouping?`,
        {
          title: "Remove Player",
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          headerClass: "p-2 border-bottom-0",
          footerClass: "p-2 border-top-0",
          centered: true,
        }
      );
      if (confirmed) {
        this.$store.dispatch("removePlayer", playerId);
      }
    },

    editPlayer(playerId) {
      this.editingPlayer = true;
      this.player = {
        ...this.players.filter((p) => p.id === playerId)[0],
      };
      this.showPlayerForm = true;
    },

    adjustHandicap(increment) {
      if (increment) {
        this.player.handicap += 1;
      } else {
        this.player.handicap -= 1;
      }
    },

    addplayer() {
      this.showPlayerForm = true;
    },

    save(close) {
      if (!this.editingPlayer) {
        this.$store.dispatch("addPlayer", this.player);
      } else {
        this.$store.dispatch("editPlayer", this.player);
      }

      if (close) {
        this.showPlayerForm = false;
      }

      this.resetPlayer();
      this.editingPlayer = false;
    },

    cancelAddPlayer() {
      this.showPlayerForm = false;
      this.editingPlayer = false;
      this.resetPlayer();
    },

    resetPlayer() {
      this.player = {
        name: "",
        handicap: 20,
      };
    },
  },
  computed: {
    ...Vuex.mapGetters(["players"]),

    playerFields() {
      return [
        {
          label: "Name",
          key: "name",
          class: "text-center",
        },
        {
          label: "Handicap",
          key: "handicap",
          class: "text-center",
        },
        {
          label: "Actions",
          key: "actions",
          class: "text-center",
        },
      ];
    },

    displayWarning() {
      return !this.hasPlayers && !this.showPlayerForm;
    },

    matchPlayers() {
      let players = [];
      if (this.players && this.players.length) {
        players = [...this.players];
        players.sort((a, b) => a.name.localeCompare(b.name));
      }
      return players;
    },

    hasPlayers() {
      return this.matchPlayers && this.matchPlayers.length > 0;
    },

    canSave() {
      if (
        this.player.name &&
        this.player.name.length > 0 &&
        this.player.handicap >= 0
      ) {
        return true;
      }
      return false;
    },
  },
  data() {
    return {
      player: {
        name: "",
        handicap: 20,
      },
      showPlayerForm: false,
      editingPlayer: false,
    };
  },
  mounted() {
    if (this.$route.query && this.$route.query.add === "true") {
      this.showPlayerForm = true;
    }
  },
};
</script>

<style scoped>
.playerForm {
}

.slider {
  width: 90%;
}
</style>
